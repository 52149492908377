<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="light-green" dark>
        <v-app-bar-nav-icon @click.prevent.stop="toggleSidebar"></v-app-bar-nav-icon>
        <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
            <span class="hidden-sm-and-down">ВПР. Ключи</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent.stop="logout">
            <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
    </v-app-bar>
</template>
<script>
export default {
    name: 'HeadBar',
    data() {
        return {}
    },
    methods: {
        toggleSidebar() {
            this.$emit('toggle-sidebar', 1);
        },
        async logout() {
            await this.$store.dispatch('user/logout');
            return this.$router.go('/').catch(() => {});
        }
    }
}
</script>