<template>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app dark color="light-green darken-3">
        <v-list dense>
            <template v-for="item in items">
                <v-row v-if="item.heading" :key="item.heading + '_heading'" align="center">
                    <v-col cols="12">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                </v-row>
                <v-list-group v-else-if="item.children" :key="item.text" v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="(child, i) in item.children" :key="i" link>
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else-if="_.has(item, 'handler')" :key="item.text" @click.prevent.stop="item.handler">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-else :key="item.text" link :to="item.href">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: 'Sidebar',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            items: [
                { heading: 'Заказы'},
                { icon: 'mdi-cart', text: 'Заказы', href: '/order' },
                { heading: 'Ключи и лицензии'},
                { icon: 'mdi-key', text: 'Ключи', href: '/code' },
                { icon: 'mdi-barcode', text: 'Коды стретч-карт', href: '/short_code' },
                { icon: 'mdi-shield-account', text: 'Лицензии', href: '/license' },
                { heading: 'Справочники'},
                { icon: 'mdi-shield-star-outline', text: 'Типы лицензий', href: '/license_type' },
                { icon: 'mdi-card-account-details-outline', text: 'Партнеры', href: '/partner' },
                { icon: 'mdi-account-arrow-left-outline', text: 'Аккаунты', href: '/user' },
                { heading: 'Обновления и версии'},
                { icon: 'mdi-git', text: 'Версии', href: '/version' },
                { heading: 'Статистика'},
                { icon: 'mdi-chart-bar', text: 'Статистика', href: '/stats' },
                { heading: 'Прочее'},
                { icon: 'mdi-logout-variant', text: 'Выйти', handler: async () => {
                    await this.$store.dispatch('user/logout');
                    return this.$router.go('/').catch(() => {});
                }}
            ]
        }
    },
    computed: {
        drawer: {
            get: function(){
                return this.visible;
            },
            set: function(newValue){
                this.$emit('update:visible', newValue)
            }   
        },
    }
}
</script>